import { all, call, cancel, fork, put, select, takeEvery } from "redux-saga/effects";
import LoaderData from "../../helpers/api";
import actions from "./actions";
import { apiUrls } from "../../settings";
import { checkAccessToken } from "../auth/saga";
import { ERROR_NOTIFICATION, globalEventBus } from "../../helpers/globalEventBus";
import { getURLOrder } from "../../containers/Page/Orders/sort";

function generateParamsFromFilters(ordersState, currentCity) {
  let filters = ordersState.filters;
  let params = {};
  if (filters.dateStart) {
    params.start_date = filters.dateStart;
  }
  if (filters.dateEnd) {
    params.finish_date = filters.dateEnd;
  }

  if (filters.places.length > 0) {
    params.place_ids = filters.places.map((item) => item.key);
  }

  if (!!filters.number) {
    params.number = filters.number;
  }

  let states = filters.states.map((item) => item.name);
  if (states.length == 0) {
    states = ordersState.availableStates.map((item) => item.name);
  }

  let courierStates = filters.courierStates.map((item) => item.name);
  if (courierStates.length == 0) {
    courierStates = ordersState.availableCourierStates.map((item) => item.name);
  }

  if (ordersState.currentPage > 1) {
    params.offset = ordersState.perPage * (ordersState.currentPage - 1);
  }

  if (!!filters.idClient) {
    params.user_ids = filters.idClient;
  }

  if (!!filters.idCourier) {
    params.courier_ids = filters.idCourier;
  }

  if (!!currentCity && !!currentCity.id) {
    params.city = currentCity.id;
  }

  params.count = ordersState.perPage;

  params.states = states;

  params.courier_states = courierStates;

  return params;
}

function generateParamsFromSorting(ordersState) {
  let params = {};
  if (!ordersState.sorting) return params;
  let sorting = { ...ordersState.sorting };
  for (let key in sorting) {
    if (!sorting[key]) {
      delete sorting[key];
    }
  }
  const keys = Object.keys(sorting);
  const keyLength = keys.length;
  if (!keyLength) return params;
  params["o"] = "";
  const fixName = (name) => {
    switch (name) {
      case "collectionTime":
        return "date_of_finish_cooking";
      case "dateOfVisit":
        return "date_of_visit";
    }
  };
  for (let i = 0; i < keyLength; i++) {
    const key = keys[i];
    params["o"] += `${getURLOrder(sorting[key])}${fixName(key)}${i !== keyLength - 1 ? "," : ""}`;
  }
  return params;
}

export function* loadData() {
  yield takeEvery("START_LOADING_ORDERS", function* () {
    yield call(checkAccessToken);
    const state = yield select();
    if (!state.Auth.idToken) {
      yield cancel();
    }

    const filtersParams = generateParamsFromFilters(state.Orders, state.Auth.city);
    const sortingParams = generateParamsFromSorting(state.Orders);
    const params = {
      ...filtersParams,
      ...sortingParams,
    };
    try {
      let orders = yield LoaderData.get(apiUrls.ordersList, params);
      if (orders.success) {
        let transfData = orders.data.map((item) => {
          return { ...item, key: item.id };
        });
        yield put({
          type: actions.SUCCESS_LOADING_ORDERS,
          data: {
            orders: transfData,
            total: orders.pagination.total,
          },
        });
      } else {
        yield put({
          type: actions.FAIL_LOADING_ORDERS,
          payload: orders,
        });
      }
    } catch (e) {
      yield put({
        type: actions.FAIL_LOADING_ORDERS,
        payload: e,
      });
    }
  });
}

export function* changeFilter() {
  yield takeEvery(actions.CHANGE_FILTERS_ORDERS, function* ({ payload }) {
    yield put({
      type: payload.name,
      data: payload.data,
    });

    yield put({
      type: actions.CHANGE_PAGE_ORDERS,
      data: 1,
    });

    yield put({
      type: actions.START_LOADING_ORDERS,
    });
  });
}

export function* changeSorting() {
  yield takeEvery(actions.CHANGE_SORTING_ORDERS, function* ({ payload }) {
    yield put({
      type: payload.name,
      data: payload.data,
    });

    yield put({
      type: actions.CHANGE_PAGE_ORDERS,
      data: 1,
    });

    yield put({
      type: actions.START_LOADING_ORDERS,
    });
  });
}

export function* changePage() {
  yield takeEvery(actions.CHANGE_PAGE_ASYNC_ORDERS, function* ({ payload }) {
    yield put({
      type: actions.CHANGE_PAGE_ORDERS,
      data: payload,
    });

    yield put({
      type: actions.START_LOADING_ORDERS,
    });
  });
}

export function* init() {
  yield takeEvery(actions.INIT_ORDERS, function* ({ payload }) {
    yield put({
      type: actions.INIT_TABLE_BY_PARAMS,
      data: payload,
    });

    yield put({
      type: actions.START_LOADING_ORDERS,
    });
  });
}

export function* failLoading() {
  yield takeEvery(actions.FAIL_LOADING_ORDERS, function* ({ payload }) {
    yield globalEventBus.emit(ERROR_NOTIFICATION, { id: "common.error", desc: payload.message });
  });
}

export function* changeSearchPlace() {
  yield takeEvery(actions.CHANGE_SEARCH_PLACE_ORDERS, function* ({ payload }) {
    if (payload.length > 2) {
      yield put({
        type: actions.LOAD_PLACES_ORDERS,
        payload: payload,
      });
    }
    const state = yield select();
    if (payload.length < 3 && state.Orders.places.length > 0) {
      yield put({
        type: actions.SET_LOAD_PLACES_ORDERS,
        data: [],
      });
    }
  });
}

export function* loadPlaces() {
  yield takeEvery(actions.LOAD_PLACES_ORDERS, function* (action) {
    try {
      yield call(checkAccessToken);
      const state = yield select();
      if (!state.Auth.idToken) {
        yield cancel();
      }

      const city = state.Auth.city;

      const params = { q: action.payload, city: !!city ? city.id : "" };
      const places = yield LoaderData.get(apiUrls.placesList, params);
      yield put({
        type: actions.SET_LOAD_PLACES_ORDERS,
        data: places.data,
      });
    } catch (e) {
      yield put({
        type: actions.FAIL_LOADING_ORDERS,
        payload: e,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadData),
    fork(changeFilter),
    fork(init),
    fork(changePage),
    fork(failLoading),
    fork(changeSearchPlace),
    fork(loadPlaces),
    fork(changeSorting),
  ]);
}
